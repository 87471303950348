$(function() {

    // CMS PAGE RECRUTEMENT OFFRES

    if($('.cms_nos_offres').length){
        $('.cms-page-column + .cms-page-column').slideUp(400);
        $('.cta-details').on('click', function(e){
            e.preventDefault();
            var columnParent = $(this).parent().parent().parent();
            columnParent.next('.cms-page-column').slideToggle(400);

            //Change CTA to "Fermer le détail" ou "Voir le détail" selon la status du btn
            if($(this).find('.button').text() == "Voir le détail"){
                $(this).find('.button').text(translate('close_details'));
            } else {
                $(this).find('.button').text(translate('see_details'));
            }

            $('.cta-details').not(this).each(function(){
                //Change CTA to "Voir le détail"    
                $(this).find('.button').text(translate('see_details'));

                var otherParent = $(this).parent().parent().parent().next('.cms-page-column');

                otherParent.each(function() {
                    if($(this).css('display') == 'block'){
                        $(this).slideToggle(400);
                    }
                });
            });
        });
    }

    if($("select[name='poste']").length){
        /*Redirection vers le post selecter dans le formulaire*/
        $("select[name='poste']").on('change', function(){
            if($(window).width() > 930) {
                var postPosition = $(this).prop('selectedIndex') + 1;

                $('html,body').animate({
                    scrollTop: $(".offre-module:nth-child(" + postPosition + ")").offset().top - 100
                }, 'slow');
            }
        });

        /*Changement du select lorsqu'on clique sur "Postuler" */
        $('.cta-postuler').on('click', function(e){
            e.preventDefault();
            var post = $(this).parent().parent().parent().parent().parent().parent();
            var postInx = post.index();
            var selectPost = $("select[name='poste'] option:eq(" + postInx + ")").val();
            wShop.$refs.wRecrutmentForm.form.poste = selectPost;

            if($(window).width() <= 930) {
                $('html,body').animate({
                    scrollTop: $("#sticky_form_carriere").offset().top - 50
                }, 'slow');
            }

            if(selectPost.trim() === 'Candidature spontanée'){
                $('html,body').animate({
                    scrollTop: $("#sticky_form_carriere").offset().top - 100
                }, 'slow');
            }

            if($(".w-recrutment-form .w-dropdown").hasClass('aSeeker')){
                $(".w-recrutment-form .w-dropdown").removeClass('aSeeker');
            } else {
                $(".w-recrutment-form .w-dropdown").addClass('aSeeker');
            }

            setTimeout(function(){
                $(".w-recrutment-form .w-dropdown").addClass('aSeeker');
            }, 500);
        });
    }

    if($('.seo_lnk .formSeoLnk').length > 1){
        $('.seo_lnk').addClass('reconfiged');
        $('.content_footer_mid').addClass('calibrate_spacing');
        
        /*$('.seo_lnk h3').each(function() {
            $(this).nextUntil('h3').andSelf().wrapAll('<div class="wrap_seos"/>');
        });*/
    }

    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
            $('.misc_cookiesinfo #shadCookie').removeClass('actif');
        }
    });
});

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function addFileInput(id_input, string) {
    var field = typeof string != "undefined" ? string : "contactProduitPhoto";
    var id = parseInt(id_input);
    var next_file = id + 1;
    var file = $("#" + field + id_input)[0].files[0];
    var error = false;
    var input =
        field == "contactProduitPhoto"
            ? $("#photoProduit_display")
            : $("#photoProduitEndommage_display");
    var nb_input =
        field == "contactProduitPhoto" ? $(input).val() : $(input).val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array("image/jpeg", "image/png", "application/pdf"); // Type de fichiers autoris�s
    if (size > 4000000) {
        alert("Poids Max : 4Mo");
        error = true;
    } else if (jQuery.inArray(type, allowedType) == -1) {
        alert("Format autoris&eacute; : jpg, png, pdf");
        error = true;
    }
    if (error) {
        if (id_input == 1) {
            $("#field_send").addClass("cache");
            $("#" + field + next_file).remove();
        }
        $("#" + field + id_input).val("");
    } else {
        if (id_input == 1) {
            $("#field_send").removeClass("cache");
        }
        if (field != "filepj") {
            var params =
                typeof string != "undefined" ? ',"' + string + '"' : "";
            var style =
                typeof string != "undefined" &&
                string != "contactProduitPhotoEndommage"
                    ? 'style="float: left; padding-left: 32px;padding-top:-2px;"'
                    : "";
            $("#" + field + id_input).after(
                "<input " +
                    style +
                    " class='product_file' id='" +
                    field +
                    next_file +
                    "' value='' type='file' name='" +
                    field +
                    next_file +
                    "' onchange='addFileInput(" +
                    next_file +
                    params +
                    ")' /> "
            );
            $(input).attr("value", next_file);
        }
    }
}

function uploadfile() {
    //fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $("#message_file").val();
    var fsize = $("#message_file")[0].files[0].size; //get file size
    var ftype = $("#message_file")[0].files[0].type; // get file type
    var allowedType = new Array("image/jpeg", "image/png", "application/pdf"); // Type de fichiers autoris�s
    if (fsize > 4000000) {
        alert("Poids Max : 4Mo");
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert("Format autorise : jpg, png, pdf");
        error = true;
    }
    if (error) {
        $("#message_file").val("");
    } else {
    }
}

function show_popup(elem) {
    openMultiShad(elem);
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
}

function openMultiShad(id) {

    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
        $("#" + id).slideDown(600);
    });

    $("#shad").css("display", "block").fadeTo("slow", 0.8).on("click", function () {
        closeMultiShad(id);
    });

    if(id === "demo_gratuit"){
        $("html, body").animate({
            scrollTop: $('#demo_gratuit').offset().top - 30,
        },500);
        $('html, body').css({
            overflow: 'hidden',
            height: '100%'
        });
    }
}

function closeMultiShad(id) {
    $("#shad").off("click");
    $('body').css('position','');

    // Hide modbox and shade and destroy shade's click event
    $("#" + id)
        .slideUp("slow")
        .queue(function() {
            if ($("#shad").is(":visible")) {
                $("#shad").fadeTo("slow", 0, function() {
                    $("#shad").css("display", "none");
                });
            }

            $(this).dequeue();
    });
    $('html, body').css({
        overflow: 'auto',
        height: 'auto'
    });
}

function deplieMsg(id) {
    $(".btn-deplier").removeClass("deplier-opened");
    $(".detail_message").slideUp();
    $("#conversation_msg_" + id)
        .slideDown()
        .parent()
        .find(".btn-deplier")
        .addClass("deplier-opened");
}

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();
    $("html, body").animate(
        {
            scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
        },
        500
    );
}

function changeLocale(locale, uri) {
    $.ajax({
        url: path_relative_root + "ajax_get_uri_by_lang.php",
        type: "post",
        data: {lang: locale, url: uri},
        success: function(data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        },
    });
}

function actionKeyCacheCache() {
    $(".key_cache_cache").bind("click", function() {
        var $objId = $(this).attr("id");
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function(option) {
    var $obj = $(this);
    var param = $.extend(
        {
            target_type: "multi",
            type_class_sufix: "styled",
            localName_new_elem: "div",
            new_elem_id: true,
            height: "auto",
            use_class: "false",
            select_noValue_color: "#aaa",
        },
        option
    );

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass("unselected selected btndown");
        if (action == "inActive") {
            styled.css({backgroundPosition: "0 0"}).addClass("unselected");
        }
        if (action == "active") {
            styled
                .css({backgroundPosition: "0 -" + height * 2 + "px"})
                .addClass("selected");
        }
        if (action == "btnDownToActive" || action == "btnDownToInActive") {
            styled.addClass("btndown");
            if (action == "btnDownToActive") {
                styled.css({backgroundPosition: "0 -" + height * 1 + "px"});
            }
            if (action == "btnDownToInActive") {
                styled.css({backgroundPosition: "0 -" + height * 3 + "px"});
            }
        }
    }

    $obj.each(function() {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array("input", "select");
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0;

        var acceptedType = new Array("radio", "checkbox");
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop("id");

        if (!isInputForm || (isInputForm == "input" && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if ((elemType = "multi")) {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data("isStyled")) {
            flag = false;
        }

        if (flag) {
            var prefix = eachLocalName == "input" ? eachType : eachLocalName;
            var styledClass = prefix + "_" + param.type_class_sufix;
            if (param.type_class_sufix == "") {
                styledClass = prefix;
            }

            if (
                !$objEach
                    .prev()
                    .is(param.localName_new_elem + "." + styledClass)
            ) {
                var $styledElem = $(
                    document.createElement(param.localName_new_elem)
                );
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({cursor: "pointer"});
                if (eachLocalName == "input") {
                    $objEach.css({display: "none"});
                }
                if (eachLocalName == "select") {
                    $objEach.css({position: "relative", opacity: 0, zIndex: 5});
                }
            } else {
                var $styledElem = $(
                    param.localName_new_elem + "." + styledClass
                );
            }

            if (param.new_elem_id) {
                $styledElem.prop("id", eachLocalName + $objEach.prop("id"));
            }

            var objHeight = param.height;
            if (objHeight == "auto") {
                objHeight = $styledElem.height();
            }
            $objEach.data("isStyled", true);
            var objChecked = $objEach.is(":checked");

            if (eachLocalName == "input") {
                if (objChecked) {
                    animeElem("active", $styledElem, objHeight);
                } else {
                    animeElem("inActive", $styledElem, objHeight);
                }
            }

            if (eachLocalName == "input") {
                if (eachType == "checkbox") {
                    $styledElem.on({
                        mousedown: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem(
                                        "btnDownToInActive",
                                        $styledElem,
                                        objHeight
                                    );
                                } else {
                                    animeElem(
                                        "btnDownToActive",
                                        $styledElem,
                                        objHeight
                                    );
                                }
                            }
                        },
                        mouseup: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop("checked", null);
                                } else {
                                    $objEach.prop("checked", "checked");
                                }
                                $objEach.trigger("change");
                            }
                        },
                    });
                    $objEach.on({
                        change: function() {
                            var objChecked = $objEach.is(":checked");

                            if (objChecked) {
                                animeElem("active", $styledElem, objHeight);
                            } else {
                                animeElem("inActive", $styledElem, objHeight);
                            }
                        },
                    });
                }
                if (eachType == "radio") {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj
                        .not($objEach)
                        .filter("[type=radio][name=" + eachName + "]");

                    $styledElem.on({
                        mousedown: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem(
                                        "btnDownToActive",
                                        $styledElem,
                                        objHeight
                                    );
                                }
                            }
                        },
                        mouseup: function(e) {
                            var objChecked = $objEach.is(":checked");
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop("checked", null);
                                    $objEach.prop("checked", "checked");
                                    animeElem("active", $styledElem, objHeight);
                                    $otherRadio.each(function() {
                                        var otherEach = $(this);
                                        animeElem(
                                            "inActive",
                                            otherEach.prev("." + styledClass),
                                            objHeight
                                        );
                                    });
                                }
                                $objEach.trigger("change");
                            }
                        },
                    });
                    $objEach.on({
                        change: function() {
                            var objChecked = $objEach.is(":checked");
                            if (objChecked) {
                                animeElem("active", $styledElem, objHeight);
                                $otherRadio.each(function() {
                                    var otherEach = $(this);
                                    animeElem(
                                        "inActive",
                                        otherEach.prev("." + styledClass),
                                        objHeight
                                    );
                                });
                            }
                        },
                    });
                }
            }
            if (eachLocalName == "select") {
                if ($styledElem.text() == "") {
                    $styledElem.text($("option:selected", $objEach).text());
                }
                if (
                    $("option:selected", $objEach).val() == "" ||
                    $("option:selected", $objEach).val() == undefined
                ) {
                    $styledElem.css({
                        color: param.select_noValue_color,
                        fontStyle: "italic",
                    });
                } else {
                    $styledElem.css({color: "", fontStyle: ""});
                }
                $objEach.on({
                    change: function() {
                        if (
                            $("option:selected", $objEach).val() == "" ||
                            $("option:selected", $objEach).val() == undefined
                        ) {
                            $styledElem.css({
                                color: param.select_noValue_color,
                                fontStyle: "italic",
                            });
                        } else {
                            $styledElem.css({color: "", fontStyle: ""});
                        }
                        $styledElem.text($("option:selected", $objEach).text());
                    },
                });
            }
        }
    });
};

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );
    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }
        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }
        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + "livraison-ajax.php",
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);
                var all_form;

                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();
                $(".styled").new_styling_form({
                    type_class_sufix: "",
                    localName_new_elem: "span",
                });
                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place("delivery");
                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }

                /*
                if(typeof $("#telephone").val() != 'undefined'){

                    $("#telephone").intlTelInput({

                        utilsScript: path_relative_root+'js/intlTelInputUtils.js?'+Math.floor(new Date().getTime() / 1000),
                        defaultCountry: country_select

                    });

                }
                if(typeof $("#mobile").val() != 'undefined'){

                    $("#mobile").intlTelInput({

                        utilsScript: path_relative_root+'js/intlTelInputUtils.js?'+Math.floor(new Date().getTime() / 1000),
                        numberType: "MOBILE",
                        defaultCountry: country_select

                    });
                }
                */
            },
        });
    }
};

var inAnime = false;
$.fn.advanceSlide = function(to, callback) {
    var $obj = $(this);
    var isVisible = $obj.is(":visible");
    var callbackfn = function() {
        if (callback && typeof callback === "function") {
            return callback();
        }
        return false;
    };
    if ($obj.data("height") == undefined || $obj.data("height") == 0) {
        if (!isVisible) {
            $obj.css({display: "block", opacity: 0, position: "absolute"});
        }
        $obj.data("height", $obj.height());
        if (!isVisible) {
            $obj.css({display: "none", opacity: "", position: ""});
        }
    }

    var objH = $obj.data("height");
    if (inAnime == false) {
        inAnime = true;
        if (to == "show" && !isVisible) {
            $obj.stop()
                .css({display: "block", overflow: "hidden", height: 0})
                .animate({height: objH}, function() {
                    $obj.css({overflow: "", height: ""});
                    inAnime = false;
                    callbackfn();
                });
        } else if (to == "hide" && isVisible) {
            $obj.stop()
                .css({display: "block", overflow: "hidden", height: objH})
                .animate({height: 0}, function() {
                    $obj.css({display: "none", overflow: "", height: ""});
                    inAnime = false;
                    callbackfn();
                });
        } else {
            inAnime = false;
        }
    }
};

function billDisplay(that) {
    var $obj = $("#wrapper_bill");
    var $this = $(that);

    if (!$this.is(":checked")) {
        $obj.stop().slideDown();
    } else {
        $obj.stop().slideUp();
    }
}

(function() {
    "use strict";

    var stickyCnt = 0;

    function initStickies() {
        var stickies = document.getElementsByClassName("sticky");

        Array.prototype.slice.call(stickies).forEach(function(sticky) {
            if (sticky.id !== "site_head_wrap") {
                if (sticky.id === "") {
                    stickyCnt += 1;
                    sticky.id = "sticky-" + stickyCnt;
                }

                sticky = new ScrollLock(sticky.id);
            }
        });
    }

    $(initStickies);
})();

$(function() {
    $(document).on("click", ".button", btnLoaderTrigger);
});

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {

            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function () {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
        // Not sure what it's for but this triggers an infinite loop
        // window.setTimeout($.fn.trigger.bind(element), 400, "click");
    }
}
/**
Enables hover state on any element when using a touch based device
 */

(function() {
    function nevermind() {
        return;
    }

    document.body.addEventListener("click", nevermind);
})();

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

/**select change function **/
$("body").on("change", "select", function() {
    $(this).addClass("changed");
});

$("select").change(function() {
    $(this).addClass("changed");
});

// Show hide div Livraison Gratuit
function cookieHeaderDelivery() {
    var d = new Date();
    d.setTime(d.getTime() + (36500  * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();

    document.cookie = "cookie_header_delivery=accepted;" + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
    }

$(function() {
    var headerDelivery = $(".header_mention");

    if (getCookie("cookie_header_delivery") != "" && headerDelivery.length) {
        headerDelivery.slideUp(500, function() {
            headerDelivery.remove();
        });
        $("#site_head_wrap").removeClass("bandeau_on");
        return;
    } else if(headerDelivery.length) {
        headerDelivery.slideDown(500);
        headerDelivery.addClass("display");
        $("body").addClass("bandeauDelivery");

        $(".close_delais").on("click", function() {
            cookieHeaderDelivery();
            headerDelivery.slideUp(500, function() {
                headerDelivery.remove();
            });
            $("body").removeClass("bandeauDelivery");
            $("#site_head_wrap").removeClass("bandeau_on");
        });
    }
});

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

$(function () {
    if($('#exit_overlay_OVI.lightbox').css("display") == "block"){
        $("#shad_abox").show();
    }

    $('#exit_overlay_OVI .close').on("click", function() {
        $("#shad_abox").hide();
        $("#exit_overlay_OVI").hide();
    });

});

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}

// SEO
$(document).ready(function () {
    // Footer seo link send post anchor
    footerSeoLinkPost();

    // Use to scroll to custom anchor
    scrollAnchor();

    // modifier le texte de msgid "sujet_question"
    modifTxtSujetQues();

    // Form demo gratuite init required input
    initFormContact();

    // Form demo gratuite init required input
    initFormDemoGratuite();

    if($(window).width() < 768) {
        var titleDrop = $('.formSeoLnk > h3 a');
        var triggerSlide = $('.formSeoLnk > h3');

        titleDrop.removeAttr('href');

        titleDrop.each(function(){
            var txtTitleDrop = $(this).html();
            $(this).replaceWith($('<span>' + txtTitleDrop + '</span>'));
        });

        triggerSlide.on('click touch', function(){
            $(this).toggleClass('triggered');
            $(this).next('ul').slideToggle(500);
        });
    }
});

/**
 * Scroll to custom anchor
 */
function footerSeoLinkPost() {
    $(document).on('click', '.seo_lnk a', function () {
        event.preventDefault();
        var hash = this.getAttribute("data-value");
        var closestForm = this.closest("form");
        $("input[name='postHash']").val(hash);
        setTimeout(function(){
            closestForm.submit();
        },10);
    });
}

/**
 * Scroll to custom anchor
 */
function scrollAnchor() {
    if (window.location.hash) {
        var hash = window.location.hash.replace('#', '');

        // Cms page anchor
        var searchTarget = hash.search(/i-[0-9]+/);
        var headerH = $('#site_head_wrap').height();
        
        if (searchTarget != -1) {            
            history.pushState("", document.title, window.location.pathname+ window.location.search); // delete hash form URL
            $('html,body').animate({
                scrollTop: $("#"+hash).offset().top - (headerH + 50)
            },1000);
        }
	}
}

/**
 * Page: Aide contact
 * remplace msgid "sujet_question" texte par
 * le texte de msgid "sujet_question_contact"
 */
function modifTxtSujetQues() {
    var contactForm = $('#form_contact1');
    var sujetLabel = contactForm.find('select[name=form_service] + .w-input-label span');
    
    if( sujetLabel.length ) {
        sujetLabel.text( translate('sujet_question_contact') );
    }
}

/**
 * Formulaire demo gratuite
 * pre selection du service
 * requête ajax mail
 */
function initFormContact() {
    var contactForm = $("#contact_form .w-contact-form")[0];
    if(contactForm){
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "faq_question";
        input.value = translate('obj_mail_contact');
        contactForm.appendChild(input);
    }
}

/**
 * Formulaire demo gratuite
 * pre selection du service
 * requête ajax mail
 */
function initFormDemoGratuite() {
    var demoGratuiteForm = $('#demo_gratuit form');
    var select = demoGratuiteForm.find('select[name=form_service]');
    var headerTitle = $('#demo_gratuit .header_lightbox .txt_alert');
    // Add mail object
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = "faq_question";
    input.value = translate('obj_mail_contact_demo');
    $('#demo_gratuit form')[0].appendChild(input);
    // If form demo gratuite exist
    if (select.length) {
        // Initialize form_service 'Demander une démo' with vue js ref (wDemoGratuiteForm)
        wShop.$refs.wDemoGratuiteForm.form.form_service = 17;
        // Check submit for AJAX
        demoGratuiteForm.on('submit', function(e) {
            e.preventDefault();  // Prevent form from submitting
            // Start loading
            wShop.$refs.wDemoGratuiteForm.isLoading = true;
            // Get datas form
            var data = demoGratuiteForm.serialize();
            // Start AJAX
            $.ajax({
                url : path_relative_root + 'ajax_send_demo_gratuite_form.php',
                type : 'post',
                data : data,
                success : function (res) {
                    // End loading
                    wShop.$refs.wDemoGratuiteForm.isLoading = false;
                    // Display validation OR error sending message
                    if (res) {
                        $("#mes_err_fieldset_demo").hide();
                        $("#message_mail_demo .error").hide();
                        demoGratuiteForm.hide();
                        $('#demo_gratuit').addClass('mail_sent');
                        headerTitle.html(translate('title_demo_success') + "<span class='desc success'>"+translate('desc_demo_success')+"</span>")
                    } else {
                        $("#mes_err_fieldset_demo").show();
                        $("#message_mail_demo .error").show();
                    }
                }
            });
        });
        
        setTimeout(function() {
            let hash = window.location.hash;
            if (typeof hash != 'undefined' && hash != null && hash != '') {
                hash = hash.substring(1);
                if (hash === "demo") { // Open lightbox demo when hash #demo is detected WP-25072
                    openMultiShad('demo_gratuit');
                }
            }
        }, 100);
    }
}

// Translation text function
(function() {
    'use strict';

    function parseArgs(args) {
        var obj = {},
            inc = 0,
            len = args.length;
        for (inc; inc < len; inc += 1) {
            obj['arg' + inc] = args[inc];
        }
        return obj;
    }

    function translate() {
        var o_data = parseArgs(arguments),
            s_resp = arguments[0];
        $.ajax({
            url: path_relative_root + 'ajax_sprintf.php',
            type: 'get',
            data: o_data,
            async: false,
            success: function(res) { s_resp = res }
        });
        return s_resp;
    }
    window.translate = translate;
}());

$(".rollover_left.achat_express .productColorFieldset .choices_list").lionbars();

/* Charte de nommage */ 

 $('.super_boutik .main_menu_contents .main_menu_itm:not(#menu_hommenos-marques, #menu_femmenos-marques) .block_lnk').wrapInner("<h2></h2>");


 /* SMOOTH SCROLLING ON PAGE ABECEDAIRE*/
if($('.abecedaire').length) {
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
    
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top -94,
        }, 500);
    });
}

$(document).ready(function () {
    $(".page_satellite .support_wrapper .form_support_wrapper .w-time-input .w-input-element").attr("placeholder", "HH :MM");
});

$(document).ready(function() {
    var select_conctact_dropdown = $(".bloc_nous_contacter #contact_form .w-contact-form select");
    select_conctact_dropdown.niceSelect().addClass('wide');
    $('.nice-select.w-input-element').addClass('wide')

    select_conctact_dropdown.on("click", function() {
        $('.nice-select.w-input-element').toggleClass('open_me').addClass('wide open');
        $('.nice-select.w-input-element .list').slideToggle(300);
    });

    select_conctact_dropdown.on("change", function() {
        wShop.$refs.wContactForm.form.form_service = this.value;
    });

    //pages satellites a target blank
    $('.wrapper_content_sat .content_inside a').attr('target','_blank');
    $('.wrapper_content_sat .content_inside table').attr('border','0');
})


$('.partenaires_main #partGoTop').on('click', function (event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
});

$(document).ready(function(){
    $('body.clients .client_ctn a').each(function(){
        var checkLink = $(this).attr('href').length;
        if(checkLink < 1){
            $(this).css({'cursor':'default', 'pointer-events':'none'});
        }
    });

    // if (window.location.hash && window.location.hash == '#candidature-spontanee') {
    //     // Scroll to last child (should always be candidature spontanee from BO) and select post
    //     setTimeout(function() {
    //         var selectPost = $("select[name='poste'] option:last").val();
    //         wShop.$refs.wRecrutmentForm.form.poste = selectPost;
    //         $('html,body').animate({
    //             scrollTop: $(".offre-module:last-child").position().top
    //         }, 'slow');
    //     }, 200);
    // }
});


/* Retirer les classe swiper sur la vue desktop */
$('body.abcdaire .abecedaire_swiper').removeClass('swiper-container');
$('body.abcdaire .abecedaire_swiper .marques_nav').removeClass('swiper-wrapper');
$('body.abcdaire .abecedaire_swiper .marques_nav .marques_list').removeClass('swiper-slide');
$('body.abcdaire .abecedaire_swiper .swiper-button-prev').hide();
$('body.abcdaire .abecedaire_swiper .swiper-button-next').hide();

// PAGES SATELLITE protection-donnees-personnelles.php
$(document).ready(function () {
    $("body.footer_privacy #row_bloc_3 .content_inside h3").each(function(){

        $("h3 + p + h3 + p + h3").prev().prev().prev().prev().addClass("initial");
        $(this).on("click", function(){
            if(!$(this).next("p").hasClass("active")){
                $("body.footer_privacy #row_bloc_3 .content_inside h3").removeClass("active");
                $("body.footer_privacy #row_bloc_3 .content_inside h3 + p").removeClass("active").slideUp();
                $(this).addClass("active");
                $(this).next("p").addClass("active").slideDown();
            }
            else{
                $(this).removeClass("active");
                $(this).next("p").removeClass("active").slideUp();
            }
            if($(this).hasClass('active') && !$(this).hasClass('initial')){
                $('html, body').animate({scrollTop: $("h3.active").prev().prev().offset().top - 100}, 500);
            }
        });

    });
});

/**
 * Main menu - submenu dropdowm
 */
$(document).ready(function () {
    var menu = $('.main_menu_itm.has_submenu');
    var initialHeaderHeight = $('#site_head_wrap').height();

    menu.each(function() {
        $(this).on('mouseenter', function(){
            $(this).find('.submenu_wrapper').stop().show(500);
            $('body').css('padding-top', '195px');
        });

        $(this).on('mouseleave', function(){
            $(this).find('.submenu_wrapper').stop().hide(500);
            $('body').css('padding-top', initialHeaderHeight);
        });
    });
});